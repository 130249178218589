@import 'styles/_helpers';

.interactive-task {
    $this: &;
    --gap: 10px;

    position: fixed;
    z-index: $level-3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $color-background;

    &__step {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    &__controls {
        position: fixed;
        display: flex;
        z-index: 4;
        justify-content: space-between;
        left: 0;
        width: 100%;
        top: 0;
        padding: $margin;
    }

    &__control {
        transition: opacity 550ms $ease-in-out-quint--custom;
        margin-right: 4px;

        &[disabled] {
            opacity: 0.1;
        }

        &--close {
            margin-left: auto;
            margin-right: 0;
        }
    }

    &__header {
        @include font-size(title);
        position: sticky;
        left: 0;
        width: 100vw;
        padding: $margin;
        padding-top: calc(#{$margin} + 2.8rem);
        padding-bottom: max(#{$margin}, 2rem);
    }

    &__title {
        font-size: inherit;
        line-height: inherit;
        text-wrap: balance;
        max-width: 46ch;
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }

    &__preamble {
        font-size: 0.98em;
        text-wrap: balance;
        max-width: 46ch;
        margin: 0.1em auto 0;
        text-align: center;
        width: 100%;
    }

    &__groups {
        display: flex;
        justify-content: safe center;
        gap: var(--gap);
        padding: 0 $spacing--small;
    }

    &__group {
        $width: clamp(9rem, 22vw, 20rem);
        $height: clamp(10rem, 35vh, 19rem);
        display: grid;
        justify-content: center;
        border-radius: $border-radius;
        padding: $spacing--small;
        width: $width;
        min-height: $height;
        flex-shrink: 0;
        background-color: color(primary, xlight);

        @include min-width(md) {
            border-radius: $border-radius--xlarge;
        }

        &--active {
            background-color: color(primary, light);
        }
    }

    &__item {
        cursor: grab;
        --width: clamp(7.4rem, 25vw, 12rem);
        --height: clamp(6rem, 18vw, 10.4rem);

        @include font-size(small);
        position: relative;
        touch-action: none;
        flex-shrink: 0;
        width: var(--width);
        height: var(--height);
        max-width: 100%;
        border-radius: $border-radius;
        padding: 0.75em;
        padding-top: calc(18px + 1.5em);
        padding-bottom: calc(18px + 0.5em);
        text-wrap: balance;
        background-color: color(primary);
        // animation: item-drop 400ms $ease-out-quad backwards;
        box-shadow: 2px 2px 8px 2px rgb(from #{color(primary, dark)} r g b / 0.3);

        @include min-width(md) {
            --width: clamp(10rem, 17vw, 15rem);
            --height: clamp(8rem, 10vw, 11rem);
            @include font-size(base, true);
            line-height: 1.22;
        }

        &--active {
            cursor: grabbing;
            z-index: $level-4;
            box-shadow: 2px 2px 8px 2px rgb(from #{color(primary, dark)} r g b / 0.9);
        }

        &--unsorted {
            margin: 0 -20px -20px;
        }

        &--wrong {
            background-color: $color-warning;
            box-shadow: 2px 2px 8px 2px rgba(shade($color-warning--dark, 20%), 0.1);
        }

        &--correct {
            background-color: $color-success;
            box-shadow: 2px 2px 8px 2px rgba(shade($color-success--dark, 20%), 0.1);
        }
    }

    &__drag-handle {
        $size: 18px;
        position: absolute;
        top: $spacing--small;
        left: calc(50% - #{$size * 0.5});

        @include max-width(md) {
            max-width: 12px;
        }
    }

    &__group-title {
        @include font-size(preamble);
        text-align: center;
        width: 100%;
        margin-bottom: $spacing--xsmall;
    }

    &__unsorted {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: center;
        width: 100vw;
        padding: $spacing $spacing--small $spacing--large;

        @include min-width(md) {
            padding: $margin;
        }

        &--enter {
            #{$this}__item {
                --from-x: 30%;
                --from-y: 30%;

                animation: item-enter 400ms $ease-out-quad backwards;
                @include animation-delay(10, 50ms);

                &:nth-child(odd) {
                    --from-y: -30%;
                }

                &:nth-child(even) {
                    --from-x: -30%;
                }
            }
        }
    }

    &__sort-list {
        display: grid;
        grid-gap: 5px;
        padding: 0 $margin;
        max-width: 100%;
        max-width: 39rem;
        margin: 0 auto 8rem;

        @include min-width(md) {
            grid-gap: 8px;
        }

        &--enter {
            #{$this}__sort-item {
                --from: 100%;

                animation: sort-item-enter 400ms $ease-out-quad backwards;
                @include animation-delay(10, 50ms);

                &:nth-child(even) {
                    --from: -100%;
                }
            }

            #{$this}__number {
                animation-delay: 500ms;
            }
        }
    }

    &__sort-item {
        cursor: grab;
        position: relative;
        @include font-size(base, true);
        line-height: 1.22;
        text-wrap: pretty;
        touch-action: none;
        display: flex;
        align-items: center;
        padding: 0.7em 1.2em;
        min-height: 4.2em;
        border-radius: $border-radius;
        background-color: color(primary);

        @include min-width(md) {
            @include font-size(preamble-small, true);
            min-height: 4.4em;
            padding: 0.8em 1.3em;
        }

        &--active {
            cursor: grabbing;
            z-index: $level-4;
            box-shadow: 1px 1px 6px 2px rgb(from #{color(primary, dark)} r g b / 0.3);
        }

        &--wrong {
            background-color: $color-warning;
        }

        &--correct {
            background-color: $color-success;
        }
    }

    &__sort-handle {
        flex-shrink: 0;
        margin-right: 1em;

        @include max-width(md) {
            display: none;
        }
    }

    &__number {
        width: 1.7em;
        flex-shrink: 0;

        @include min-width(md) {
            position: absolute;
            left: -(2.1em);
            width: 2.5em;

            animation: number-enter 400ms $ease-out-quad backwards;
        }
    }

    &__summary {
        $size: 2.5em;
        @include font-size(small, true);
        position: absolute;
        width: $size;
        height: $size;
        display: grid;
        place-content: center;
        border-radius: 50%;
        top: -($size * 0.2);
        right: -($size * 0.2);

        &--group {
            border-radius: $size;
            width: fit-content;
            min-width: $size;
            padding: 0 0.75em;
        }

        &--wrong {
            background-color: $color-warning;
            box-shadow: -3px 3px 8px rgba(shade($color-warning--dark, 25%), 0.2);
        }

        &--correct {
            background-color: $color-success;
            box-shadow: -3px 3px 8px rgba(shade($color-success--dark, 25%), 0.2);
        }
    }

    &__footer {
        position: fixed;
        display: grid;
        place-content: center;
        left: 0;
        bottom: 0;
        width: 100vw;

        padding: 1em 1em 1.5em;
        background-color: $color-background;
        box-shadow: 0 0 20px rgba(black, 0.05);

        // background: linear-gradient(rgba($color-background, 0), rgba($color-background, 1) 20px);

        animation: next-enter 300ms $ease-out-quart backwards;
    }

    &__next {
        @include font(base, bold);
        min-height: 3rem;
        min-width: 7.6rem;

        @include min-width(md) {
            min-height: 3.5rem;
            min-width: 8.8rem;
        }
    }

    &__completed {
        padding: $margin;
        padding-top: calc(#{$margin} + 2.8rem);
        padding-bottom: max(#{$margin}, 2rem);
    }

    &__cover {
        @include font-size(title);
        display: grid;
        width: 100%;
        grid-gap: $gutter;
        padding: $spacing--large;
        border-radius: $border-radius--xlarge;
        background-color: color(primary, light);

        animation: cover-enter 600ms $ease-in-out-quint--custom backwards;

        @include min-width(lg) {
            grid-template-columns: auto 220px;
        }
    }

    &__cover-title,
    &__cover-preamble {
        font-size: inherit;
        line-height: inherit;
        margin: 0 0 0.2em;
        max-width: 40ch;
        text-wrap: balance;
    }

    &__cover-content {
        text-align: center;

        @include min-width(lg) {
            text-align: left;
        }
    }

    &__restart:not(:empty) {
        @include font(base, bold);
        @include font-size(base);
        padding-left: 2em;
        padding-right: 2em;
        margin-top: 2rem;

        @include min-width(md) {
            margin-top: 3rem;
        }
    }

    &__illustration {
        @include max-width(lg) {
            display: none;
        }
    }

    // &__cover-preamble {
    //     opacity: 0.8;
    // }

    &__entry {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        padding: $spacing--small $spacing--small $spacing;
        border-radius: $border-radius--large;
        background-color: color(primary, light);
        aspect-ratio: 1/1;

        animation: entry-enter 600ms $ease-in-out-quint--custom backwards;
        @include animation-delay(10, 50ms);

        &:hover,
        &:focus:focus-visible {
            background-color: color(primary);

            #{$this}__entry-tag {
                background-color: color(primary, light);
            }
        }
    }

    &__entry-illustration {
        display: block;
        max-width: min(50%, 10rem);
        margin: auto;
    }

    &__entry-tag {
        @include font-size(small);
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 4px 10px 3px;
        border-radius: $border-radius--small;
        background-color: color(primary);
        transition: background-color 200ms $ease-out-quart;

        @include min-width(md) {
            padding: 6px 12px 5px;
            top: $spacing--small;
            right: $spacing--small;
        }
    }

    &__entry-title {
        @include font-size(sub-title);
        margin: 0;
    }
}

@keyframes sort-item-enter {
    from {
        opacity: 0;
        transform: translateY(var(--from)) scale(0.8);
    }

    40% {
        opacity: 1;
    }
}

@keyframes item-enter {
    from {
        opacity: 0;
        transform: translate(var(--from-x), var(--from-y)) scale(0.6);
    }

    20% {
        opacity: 1;
    }
}

@keyframes number-enter {
    from {
        opacity: 0;
        transform: translateX(80%);
    }
}

@keyframes item-drop {
    from {
        transform: scale(0.95);
    }
}

@keyframes next-enter {
    from {
        opacity: 0;
        transform: translateY(50%);
    }
}

@keyframes cover-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}
