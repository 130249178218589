@import 'styles/_helpers';

.learning-aids {
    $this: &;
    $easing-enter: 600ms $ease-out-quart;
    $easing-exit: 600ms $ease-in-quart;
    margin-top: $spacing;
    margin-bottom: $spacing;

    &__list {
        display: grid;
        grid-gap: $gutter;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: $spacing;

        @include min-width(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__link {
        --bg: #{color(primary, light)};
        @include font-size(small);
        display: block;
        width: 100%;
        height: 100%;
        padding: $spacing--small;
        border-radius: $border-radius;
        background-color: var(--bg);
        text-align: center;

        @include min-width(xl) {
            padding: $spacing;
            border-radius: $border-radius--large;
        }

        transition: background-color 200ms $ease-out-quart;

        &:hover,
        &:focus {
            --bg: #{color(primary)};

            #{$this}__topic {
                background-color: color(primary, light);
            }
        }

        &--square {
            aspect-ratio: 1/1;

            @include max-width(1500px) {
                padding-bottom: $spacing--small;
            }

            #{$this}__types {
                width: 68%;
                margin-top: 5%;

                @include min-width(md) {
                    width: 60%;
                }

                @include min-width(1500px) {
                    width: 73%;
                }
            }

            #{$this}__title {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 1.2em;
            }
        }
    }

    &__types {
        width: 80%;
        margin: $spacing--large auto auto;

        @include min-width(md) {
            margin: $spacing auto auto;
            width: 90%;
        }
    }

    &__illustration {
        position: relative;
        width: 90%;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__image {
        //position: absolute;
        width: 200px;
        height: 175px;
        //margin-bottom: auto;
        max-height: 175px;
        max-width: 200px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__poster {
        position: relative;
        aspect-ratio: 3/2;
        width: 100%;
        overflow: hidden;
        margin: auto;

        &:after {
            --bg-0: color-mix(in srgb, var(--bg), #0000 100%);
            --bg-1: color-mix(in srgb, var(--bg), #0000 0%);
            content: '';
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 0;
            height: 50%;
            width: 100%;
            background: linear-gradient(var(--bg-0), var(--bg-1));
            transition: background 200ms $ease-out-quart;
        }
    }

    &__poster-img {
        $shadow: 10px;
        position: absolute;
        top: $shadow;
        left: $shadow;
        width: calc(100% - #{$shadow * 2});
        height: 100%;
        object-fit: cover;
        object-position: top center;
        box-shadow: 0 0 $shadow rgba(black, 0.1);
    }

    &__item {
        position: relative;
        display: flex;
        justify-content: center;
        aspect-ratio: 1/1;
        overflow: hidden;
        animation: entry-enter 600ms $ease-in-out-quint--custom both;
        @include animation-delay(10, 50ms);
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__duration {
        margin: $spacing--small 0 10px;
    }

    &__main-title {
        margin-top: $section-spacing;
        margin-bottom: $spacing;
    }

    &__title {
        @include font(base, bold);
        @include font-size(base);
        //margin-top: $spacing;

        @supports (display: -webkit-box) {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        @include min-width(md) {
            @include font-size(preamble);
        }

        @include min-width(lg) {
            @include font-size(base);
        }

        @include min-width(xxl) {
            @include font-size(preamble);
        }

        &--long-word {
            @include hyphens;
        }
    }

    &__topic {
        @include text-overflow;
        position: absolute;
        z-index: 2;
        max-width: calc(100% - 20px);
        top: 12px;
        right: 12px;
        padding: 4px 10px 3px;
        border-radius: $border-radius--small;
        background-color: color(primary);
        transition: background-color 200ms $ease-out-quart;

        @include min-width(md) {
            padding: 6px 12px 5px;
            @include font-size(small);
            top: $spacing--small;
            right: $spacing--small;
        }
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}
