@import 'styles/_helpers';

.quiz-entry {
    $this: &;
    display: flex;
    flex-direction: column;
    aspect-ratio: 1/1;
    animation: entry-enter 600ms $ease-in-out-quint--custom backwards;
    @include animation-delay(10, 50ms);

    padding: $spacing--xsmall;
    border-radius: $border-radius--large;
    background-color: color(primary, light);

    // &:hover,
    // &:focus:focus-visible {
    //     background-color: color(primary);
    // }

    &__content {
        display: grid;
        gap: 0.5rem;
        text-align: center;
        padding: 0.5rem 1rem 0.75rem;

        @include min-width(md) {
            padding: 0.75rem 1rem;
        }
    }

    &__summary {
        @include font-size(base);
        color: $color-muted;
    }

    &__title {
        @include font-size(sub-title);
        margin: 0;
    }

    &__button {
        min-width: 50%;
        margin: 0.6rem auto 0;
    }

    &__illustration {
        display: block;
        margin: auto;
        max-width: min(170px, 40%);
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}
